<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    import {authCodeLogin} from "@/url/api.js";
    import {mapGetters} from "vuex";

    export default {
        name: 'App',
        data() {
            return {}
        },
        mounted() {
        },
        created() {
            authCodeLogin().then(res =>{
                sessionStorage.setItem("title_tag",JSON.stringify(res))
            })
        },
        methods: {

        },
        computed: {
            ...mapGetters({
                indexType: 'getTYpe'
            }),
        },
    }
</script>

<style>


</style>
