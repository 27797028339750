import Vue from 'vue';
import App from './App.vue';
import Router from "vue-router";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'element-ui/lib/theme-chalk/index.css';
import store from '../store/index'
import ElementUI from 'element-ui';

Vue.use(ElementUI);
Vue.config.productionTip = false;

// 全局注册 jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;


//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 引入 Vue Router 并创建路由对象
Vue.use(Router);

const routes = [
    {
        path: '/laSiZhiLu',
        name: "laSiZhiLu",
        component: () => import('../src/components/item/laSiZhiLu.vue'),
    },
    {
        path: '/mei',
        name: "mei",
        component: () => import('../src/components/item/mei.vue'),
    },
    {
        path: '/essay',
        name: "essay",
        component: () => import('../src/components/item/huo/essay'),
    },
    {
        path: '/xian',
        name: "xian",
        component: () => import('../src/components/item/xian.vue'),
    },
    {
        path: '/alipay',
        name: "alipay",
        component: () => import('../src/components/item/alipay.vue'),
    },
    {
        path: '/tai',
        name: "tai",
        component: () => import('../src/components/item/taiBei.vue'),
    },
    {
        path: '/song',
        name: "song",
        component: () => import('../src/components/item/song.vue'),
    },
    {
        path: '/jac',
        name: "jac",
        component: () => import('../src/components/item/jac.vue'),
    },
    {
        path: '/vip',
        name: "vip",
        component: () => import('../src/components/item/vip.vue'),
    },
    {
        path: '/quanYa',
        name: "quanYa",
        component: () => import('../src/components/item/quanYa.vue'),
    },
    {
        path: '/holdeYa',
        name: "holdeYa",
        component: () => import('../src/components/item/holdeYa.vue'),
    },    {
        path: '/rushncash-jun',
        name: "rushncash-jun",
        component: () => import('../src/components/item/rushncash-jun.vue'),
    },
    {
        path: '/omaha-jun',
        name: "omaha-jun",
        component: () => import('../src/components/item/omaha-jun.vue'),
    },
    {
        path: '/zhiNeng',
        name: "zhiNeng",
        component: () => import('../src/components/active/zhiNeng.vue'),
        children: [
            {
                path: 'box',
                component: () => import('../src/components/active/zhiNengItem/box.vue'),
            },
            {
                path: 'jueSai',
                component: () => import('../src/components/active/zhiNengItem/jueSai.vue'),
            },            {
                path: 'miao',
                component: () => import('../src/components/active/zhiNengItem/miao.vue'),
            },            {
                path: 'nft',
                component: () => import('../src/components/active/zhiNengItem/nft.vue'),
            },            {
                path: 'quanYa',
                component: () => import('../src/components/active/zhiNengItem/quanYa.vue'),
            },            {
                path: 'rongAyo',
                component: () => import('../src/components/active/zhiNengItem/rongAyo.vue'),
            },            {
                path: 'saiShi',
                component: () => import('../src/components/active/zhiNengItem/saiShi.vue'),
            },            {
                path: 'sheJiao',
                component: () => import('../src/components/active/zhiNengItem/sheJiao.vue'),
            },            {
                path: 'straddle',
                component: () => import('../src/components/active/zhiNengItem/straddle.vue'),
            },
            {
                path: 'wanNeng',
                component: () => import('../src/components/active/zhiNengItem/wanNeng.vue'),
            },  {
                path: 'zhanJi',
                component: () => import('../src/components/active/zhiNengItem/zhanJi.vue'),
            },  {
                path: 'zhiNeng',
                component: () => import('../src/components/active/zhiNengItem/zhiNeng.vue'),
            },
        ]
    },
    {
        path: '/shortdeck-jun',
        name: "shortdeck-jun",
        component: () => import('../src/components/item/shortdeck-jun.vue'),
    },
    {
        path: '/finaltablebetting',
        name: "finaltablebetting",
        component: () => import('../src/components/item/finaltablebetting.vue'),
    },
    {
        path: '/pokercraft',
        name: "pokercraft",
        component: () => import('../src/components/item/pokercraft.vue'),
    },
    {
        path: '/badge-of-honor',
        name: "badge-of-honor",
        component: () => import('../src/components/item/badge-of-honor.vue'),
    },
    {
        path: '/holdemJun',
        name: "holdemJun",
        component: () => import('../src/components/item/holdemJun.vue'),
    },
    {
        path: '/smarthud',
        name: "smarthud",
        component: () => import('../src/components/item/smarthud.vue'),
    },
    {
        path: '/miaoItem',
        name: "miaoItem",
        component: () => import('../src/components/item/miao.vue'),
    },
    {
        path: "/",
        name: "index",
        // redirect: "/game"
        component: () => import('../src/index/index.vue'),
        children: [
            {
                path: '',
                component: () => import('../src/components/bodyIndex.vue'),
            }]
    },
    {
        path: "/game",
        name: "game",
        component: () => import('../src/index/index.vue'),
        children: [
            {
                path: 'details',
                component: () => import('../src/components/active/details.vue'),
            },
            {
                path: '',
                component: () => import('../src/components/bodyIndex.vue'),
            },

            {
                path: 'active',
                component: () => import('../src/components/active'),
            },
            {
                path: 'clan',
                component: () => import('../src/components/clan.vue'),
            },
            {
                path: 'type',
                component: () => import('../src/components/bodyType.vue'),
                children: [
                    {
                        path: 'guaranteed',
                        component: () => import('../src/components/typeGame/guaranteed.vue'), // 每日常规保底
                    },
                    {
                        path: 'highRoller',
                        component: () => import('../src/components/typeGame/highRoller.vue'), // 豪客赛
                    },
                    {
                        path: 'zodiacSigns',
                        component: () => import('../src/components/typeGame/zodiacSigns.vue'), // 十二生肖
                    },
                    {
                        path: 'bountyHunter',
                        component: () => import('../src/components/typeGame/bountyHunter.vue'), // 赏金猎人
                    },
                    {
                        path: 'omaha',
                        component: () => import('../src/components/typeGame/omaha.vue'), // 奥马
                    },
                    {
                        path: 'championship',
                        component: () => import('../src/components/typeGame/championship.vue'), // 锦标
                    },
                ]
            }
        ]
    },
];

// 创建路由对象并且传入规则
const router = new Router({
    routes,
    mode: 'history',
});

new Vue({
    router, // 导入路由对象
    render: h => h(App),
    store,
}).$mount('#app');
