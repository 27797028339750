import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const actions = {

};
const mutations = {
    SET_INDEX_TYPE(state, value){
        state.indexType = value
    },
    SET_HTML_BODY(state, value){
        state.htmlBody = value
    }
};
const state = {
     // 筛选下表位置
    indexType: 0,
    htmlBody: ''
};
const getters = {
    getTYpe(state){
        return state.indexType;
    },
    getHtmlBody(state){
        return state.htmlBody;
    }
}
export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})