import request from "@/url/request.js";

const baseUrl = '/wp-json/wp/v2'

//获取分类
export function authCodeLogin(params) {
    return request({
        url: baseUrl + "/categories",
        method: "get",
    });
}

//根据分类id 获取文章信息
export function posts(categories) {
    return request({
        url: baseUrl + `/posts?categories=${categories}`,
        method: "get",
    });
}

//获取图片
export function media(media) {
    return request({
        url: baseUrl + `/media/${media}`,
        method: "get",
    });
}

//其实，也不一定就是params，也可以是 query 还有 data 的呀！
//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。